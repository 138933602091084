import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StoragedbService {
  private _storager!: Storage;

  constructor(private storage: Storage) {}

  public async init() {
    if (!this._storager) {
      this._storager = await this.storage.create();
    }
  }

  public async insert(table: string, value_data: any, primary_key: string[]) {
    await this.init();
    let values_inserted = await this.select(table);
    if (Array.isArray(value_data)) {
      value_data.forEach((value) => {
        values_inserted[primary_key.map((pk) => value[pk]).join('_')] =
          value;
      });
    } else
      values_inserted[primary_key.map((pk) => value_data[pk]).join('_')] =
        value_data;
    await this._storager?.set(table, values_inserted);
  }

  public async select(table: string, query: any = null) {
    await this.init();
    let res = await this._storager?.get(table);
    return res || {};
  }

  public async clear(table: string) {
    await this.init();
    await this._storager?.remove(table);
  }
}
