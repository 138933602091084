export class ContentModel {
  public checked: string = '0';
  public textcontent: string = "";
  public observationcontent: string = "";

  public static fromJson(js: any) {
    let obj = new ContentModel();
    obj.checked = js.checked;
    obj.check = js.checked == '1';
    obj.textcontent = js.textcontent;
    obj.observationcontent = js.observationcontent;
    return obj;
  }

  set check(check: boolean) {
    this.checked = check ? '1' : '0';
  }

  get check(): boolean {
    return this.checked == '1';
  }

  public toJson() {
    return {
      checked: this.check ? '1' : '0',
      textcontent: this.textcontent,
      observationcontent: this.observationcontent,
    };
  }
}
