import { Header } from './header';
import { HttpHeaders } from '@angular/common/http';

export class HeaderPattern extends Header {
  get jsonHeader() {
    this.headerobject.append('Content-Type', 'application/json');
    this.headerobject.append('Accept-Encoding', 'application/json');
    this.headerobject.append('Connection', 'keep-alive');
    return this.headerobject;
  }

  public getJsonAuthorizationHeader(hashcode: string) {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${hashcode}`,
    });
    console.log('[HEADER] says => ', httpOptions);

    return httpOptions;
  }

  public getFormAuthorizationHeader(hashcode: string) {
    const httpOptions = new HttpHeaders({
      Authorization: `Bearer ${hashcode}`,
    });
    console.log('[HEADER] says => ', httpOptions);

    return httpOptions;
  }

  get fileHeader() {
    this.newHeader();
    this.headerobject.append('Content-Type', 'application/json');
    this.headerobject.append('Connection', 'keep-alive');
    return this.headerobject;
  }
}
