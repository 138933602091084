import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'visitmodal',
    loadChildren: () => import('./modals/visitmodal/visitmodal.module').then( m => m.VisitmodalPageModule)
  },
  {
    path: 'clientmodal',
    loadChildren: () => import('./modals/clientmodal/clientmodal.module').then( m => m.ClientmodalPageModule)
  },
  {
    path: 'contentmodal',
    loadChildren: () => import('./modals/contentmodal/contentmodal.module').then( m => m.ContentmodalPageModule)
  },
  {
    path: 'signdrawn',
    loadChildren: () => import('./modals/signdrawn/signdrawn.module').then( m => m.SigndrawnPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'galerymodal',
    loadChildren: () => import('./modals/galerymodal/galerymodal.module').then( m => m.GalerymodalPageModule)
  },
  {
    path: 'emailmodal',
    loadChildren: () => import('./modals/emailmodal/emailmodal.module').then( m => m.EmailmodalPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
