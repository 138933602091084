export class UserModel {
  public id: string;
  public user: string;
  public password: string;
  public hashcode: string;
  public name: string;
  public status: string;
  public master: string;
  public dtupdate: string;

  public static fromJson(js: any) {
    let obj = new UserModel();
    obj.id = js.id;
    obj.user = js.user;
    obj.hashcode = js.hashcode;
    obj.name = js.name;
    obj.status = js.status;
    obj.master = js.master;
    obj.dtupdate = js.dtupdate;
    return obj;
  }

  public toJson() {
    return {
      id: this.id,
      user: this.user,
      password: this.password,
      hashcode: this.hashcode,
      name: this.name,
      status: this.status,
      master: this.master,
      dtupdate: this.dtupdate,
    };
  }
}
