import * as moment from 'moment';
import { ContentModel } from './content.model';
import { ImagesModel } from './images.model';
import { FilesModel } from './files.model';

export class VisitModel {
  public id: string;
  public idmobile: string;
  public idmarker: string;
  public idclient: string;
  public dsclient: string;
  public objective: string;
  public iduser: string;
  public dtvisit: string = moment().format('YYYY-MM-DD HH:mm:ss');
  public dtvisitend: string = moment().format('YYYY-MM-DD HH:mm:ss');
  public content: ContentModel[] = [];
  public sendmail: string;
  public signature: string;
  public signaturelink?: string;
  public rescheduled?: string;
  public latsign?: string;
  public lngsign?: string;
  public dtsign?: string;
  public lat: string;
  public lng: string;
  public dtupdate: string;
  public status: string;
  public images: ImagesModel[] = [];
  public files: FilesModel[] = [];

  public static fromJson(js: any) {
    let obj = new VisitModel();
    obj.id = js.id;
    obj.idmobile = js.idmobile || js.id;
    obj.idclient = js.idclient;
    obj.idmarker = js.idmarker;
    obj.dsclient = js.dsclient;
    obj.objective = js.objective;
    obj.iduser = js.iduser;
    obj.dtvisit = js.dtvisit;
    obj.dtvisitend = js.dtvisitend;
    obj.content = js.content.map((content) => ContentModel.fromJson(content));
    obj.images = ImagesModel.fromJsonArray(js.images);
    obj.files = FilesModel.fromJsonArray(js.files);
    obj.sendmail = js.sendmail;
    obj.rescheduled = js.rescheduled;
    obj.signature = js.signature;
    obj.signaturelink = js.signaturelink;
    obj.latsign = js.latsign;
    obj.lngsign = js.lngsign;
    obj.dtsign = js.dtsign;
    obj.lat = js.lat;
    obj.lng = js.lng;
    obj.dtupdate = js.dtupdate;
    obj.status = js.status;
    return obj;
  }

  set rescheduleddata(rescheduled) {
    this.rescheduled = rescheduled ? '1' : '0';
  }

  get rescheduleddata() {
    return this.rescheduled === '1';
  }

  get completed() {
    return Number(this.status) == 1;
  }

  get datetime() {
    return moment(this.dtvisit).format('DD/MM/YYYY HH:mm');
  }

  set setDtVisit(value: moment.Moment) {
    this.dtvisit = value.format('YYYY-MM-DD HH:mm:ss');
  }

  set setDtVisitEnd(value: moment.Moment) {
    this.dtvisitend = value.format('YYYY-MM-DD HH:mm:ss');
  }

  setMail(mail: string) {
    let mails = this.mails;
    mails.push(mail);
    console.log('mails => ', mails);

    this.sendmail = mails.join(';');
  }

  removeMail(index: number) {
    let mails = this.mails;
    mails.splice(index, 1);
    console.log('removeu => sobra => ', mails);

    this.sendmail = mails.join(';') || null;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  get mails() {
    try {
      return this.sendmail?.split(';').filter((i) => i);
    } catch (error) {
      return [];
    }
  }

  get calendarForm() {
    return (
      moment(this.dtvisit).format('YYYY-MM-DD') +
      'T' +
      moment(this.dtvisit).format('HH:mm:ss')
    );
  }

  get calendarFormEnd() {
    return (
      moment(this.dtvisitend).format('YYYY-MM-DD') +
      'T' +
      moment(this.dtvisitend).format('HH:mm:ss')
    );
  }

  get hasimages() {
    return this.images.length > 0;
  }

  set calendarForm(value: any) {
    let time = value.detail.value;
    let date: string[] = time.split('T');
    this.dtvisit = moment(
      date[0] + ' ' + date[1].substring(0, 8),
      'YYYY-MM-DD HH:mm:ss'
    ).format('YYYY-MM-DD HH:mm:ss');
  }

  set calendarFormEnd(value: any) {
    let time = value.detail.value;
    let date: string[] = time.split('T');
    this.dtvisitend = moment(
      date[0] + ' ' + date[1].substring(0, 8),
      'YYYY-MM-DD HH:mm:ss'
    ).format('YYYY-MM-DD HH:mm:ss');
  }

  get statusvisit() {
    if (this.rescheduleddata) {
      return {
        backgroundColor: '#1e89af',
        status: 'Renarcada',
      };
    } else if (Number(this.status) == 0 || !this.status) {
      return {
        backgroundColor: '#aa4105',
        status: 'Pendente',
      };
    } else if (Number(this.status) == 1) {
      return {
        backgroundColor: '#6f2c83',
        status: 'Salva',
      };
    } else if (Number(this.status) == 2) {
      return {
        backgroundColor: '#2c833d',
        status: 'Enviada',
      };
    }
  }

  public generateID() {
    this.idmobile =
      this.idmobile || this.id || this.iduser + String(moment().valueOf());
  }

  public toJson() {
    return {
      id: this.id,
      idclient: this.idclient,
      idmobile: this.idmobile,
      idmarker: this.idmarker,
      iduser: this.iduser,
      dtvisit: this.dtvisit,
      dtvisitend: this.dtvisitend,
      content: this.content.map((c) => c.toJson()),
      objective: this.objective,
      rescheduled: this.rescheduled,
      sendmail: this.sendmail,
      signature: this.signature,
      signaturelink: this.signaturelink,
      latsign: this.latsign,
      lngsign: this.lngsign,
      dtsign: this.dtsign,
      lat: this.lat,
      lng: this.lng,
      dtupdate: this.dtupdate,
      status: this.status,
      images: this.images.map((i) => i.toJson()),
      files: this.files.map((i) => i.toJson()),
    };
  }
}
