import { Injectable } from '@angular/core';
import { UserModel } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserloggedService {
  private _user: UserModel = new UserModel();

  constructor() {}

  set user(user: UserModel) {
    this._user = user;
  }

  get user() {
    return this._user;
  }

  get hashcode() {
    return this._user.hashcode;
  }

  public saveUser() {
    if (this._user.id) {
      localStorage.setItem('user', JSON.stringify(this.user.toJson()));
    }
  }

  public clearSavedUser() {
    localStorage.setItem('user', '');
  }

  get savedUser() {
    try {
      let res = localStorage.getItem('user');
      this._user = UserModel.fromJson(JSON.parse(res));
      return this._user;
    } catch {
      return new UserModel();
    }
  }
}
