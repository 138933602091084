type backgroundType = 'danger' | 'success' | 'warning' | 'normal';

const colors = {
  normal: '#2b2c2bed',
  danger: '#9c0217',
  warning: '#e57104',
  success: '#049d02',
};

interface INotification {
  timeout?: number;
  height?: string;
  backgroundColor?: backgroundType;
  color?: string;
}

const patternProps: INotification = {
  timeout: 4000,
  height: 'auto',
  backgroundColor: 'normal',
  color: '#fff',
};

export const sendNotification = (
  text: string,
  props: INotification,
  onClick = null
) => {
  return new Promise((resolve) => {
    let properties = patternProps;
    properties = { ...properties, ...props };
    console.log(properties);

    let aviso: HTMLElement = document.createElement('div');

    let estilo = aviso.style;
    estilo.backgroundColor = `${colors[properties.backgroundColor]}`;
    estilo.color = properties.color;
    estilo.transition = 'opacity 0.8s';
    estilo.opacity = '0';
    estilo.right = '0';
    estilo.left = '0';
    estilo.position = 'fixed';
    estilo.zIndex = '99999999';
    estilo.height = properties.height;
    estilo.padding = '12px';
    estilo.textAlign = 'center';
    estilo.borderRadius = '5px';
    estilo.display = 'flex';
    estilo.justifyContent = 'center';
    estilo.alignItems = 'center';
    estilo.bottom = `20px`;
    estilo.userSelect = 'none';
    estilo.outline = '0';
    estilo.margin = '0 16px';
    estilo.fontSize = '12px';

    setTimeout(() => {
      estilo.opacity = '1';
    }, 100);

    const closer = () => {
      estilo.opacity = '0';
      setTimeout(() => {
        if (aviso.parentElement) aviso.parentElement.removeChild(aviso);
      }, 800);
    };

    let timer_close = setTimeout(() => {
      closer();
      resolve(false);
    }, properties.timeout);

    let textdiv: HTMLElement = document.createElement('div');
    textdiv.innerHTML = text;
    textdiv.onclick = () => {
      closer();
      resolve(true);
    };

    let close: HTMLElement = document.createElement('div');
    close.innerHTML = 'X';
    close.style.position = 'absolute';
    close.style.right = '12px';
    close.style.top = '8px';
    close.style.padding = '4px';
    close.style.fontWeight = 'bold';
    close.style.cursor = 'pointer';

    close.onclick = () => {
      clearTimeout(timer_close);
      closer();
      resolve(false);
    };

    aviso.onclick = () => {
      clearTimeout(timer_close);
      closer();
      resolve(false);
      if (onClick) onClick();
    };

    // aviso.appendChild(close);
    aviso.appendChild(textdiv);

    document.body.appendChild(aviso);
  });
};
