export class FilesModel {
  public id?: string;
  public id_visit?: string;
  public name?: string;
  public link?: string;
  public dtupdate?: string;

  public static fromJson(js: any) {
    let obj = new FilesModel();
    obj.id = js.id;
    obj.id_visit = js.id_visit;
    obj.name = js.name;
    obj.link = js.link;
    obj.dtupdate = js.dtupdate;
    return obj;
  }

  public static fromJsonArray(arr: any[]) {
    return arr?.map((e) => FilesModel.fromJson(e)) || [];
  }

  public toJson() {
    return {
      id: this.id,
      id_visit: this.id_visit,
      name: this.name,
      link: this.link,
      dtupdate: this.dtupdate,
    };
  }
}
