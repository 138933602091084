import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VisitModel } from 'src/app/models/visit.model';
import { environment } from 'src/environments/environment';
import { sendNotification } from '../../notityer/notifyer';
import { UserloggedService } from '../../storage/userlogged/userlogged.service';
import { HeaderPattern } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

export interface IGetVisit {
  month: number;
  year: number;
}

@Injectable({
  providedIn: 'root',
})
export class VisitrepositoryService {
  private header: HeaderPattern = new HeaderPattern();

  constructor(
    private http: HttpClient,
    private userlogged: UserloggedService
  ) {}

  async get(props: IGetVisit): Promise<VisitModel[]> {
    try {
      const res = (await this.http
        .get(`${URL_SERVER}/visits`, {
          headers: this.header.getJsonAuthorizationHeader(
            this.userlogged.hashcode
          ),
        })
        .toPromise()) as any[];
      // const res = (await this.http
      //   .get('/assets/api/visit.json')
      //   .toPromise()) as any[];
      return res.map((r) => VisitModel.fromJson(r)) as VisitModel[];
    } catch (error) {
      console.error(error);
      sendNotification('Erro ao baixar dados de Visitas!', {
        backgroundColor: 'danger',
      });
      return [];
    }
  }

  async set(visit: VisitModel){
    try {
      const res = (await this.http
        .post(`${URL_SERVER}/visits`, visit.toJson(), {
          headers: this.header.getJsonAuthorizationHeader(
            this.userlogged.hashcode
          ),
        })
        .toPromise()) as any[];
      return true;
    } catch (error) {
      console.error(error);
      sendNotification('Erro ao baixar dados de Visitas!', {
        backgroundColor: 'danger',
      });
      return false;
    }
  }
}
