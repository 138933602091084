import { Injectable, OnInit } from '@angular/core';
import { VisitModel } from '../models/visit.model';
import {
  IGetVisit,
  VisitrepositoryService,
} from '../services/repository/visitrepository/visitrepository.service';
import { StoragedbService } from '../services/storage/persist/storagedb.service';
import { UserloggedService } from '../services/storage/userlogged/userlogged.service';

@Injectable({
  providedIn: 'root',
})
export class VisitdriverService implements OnInit {
  private _table: string = 'visits';
  private _queue_send: VisitModel[] = [];

  constructor(
    private db: StoragedbService,
    private userlogged: UserloggedService,
    private visitrepo: VisitrepositoryService
  ) {
    this.init();
  }

  ngOnInit(): void {}

  public async init() {
    // console.log('[INFO] User connected -> ', this.userlogged.hashcode);
    this._table = `visits_${this.userlogged.user.hashcode}`;
    await this.checkPending();
  }

  public async all() {
    const res = await this.db.select(this._table);
    return Object.keys(res).map((key) => VisitModel.fromJson(res[key]));
  }

  public async checkPending() {
    let res = await this.all();
    this._queue_send = [];
    res.forEach((item) => {
      if (Number(item.status) == 1) {
        this.addQueueSend(item);
      }
    });
  }

  public async set(values: any) {
    console.log('[IO] inserting => ', values);
    await this.db.insert(this._table, values, ['idmobile']);
  }

  public async sync(props: IGetVisit) {
    const sended = await this.sendQueue();
    if (sended) {
      this._queue_send = [];
      await this.set(await this.visitrepo.get(props));
    }
  }

  get inQueue() {
    return this.sizeQueue > 0;
  }

  get sizeQueue() {
    return this._queue_send.length;
  }

  public async addQueueSend(visit: VisitModel) {
    this._queue_send.push(visit);
  }

  public async sendQueue() {
    try {
      let sender: boolean[] = [];
      for (let visit of this._queue_send) {
        sender.push(await this.sendVisit(visit));
      }
      return sender.every((e) => e);
    } catch {
      return false;
    }
  }

  public async sendVisit(visit: VisitModel): Promise<boolean> {
    return await this.visitrepo.set(visit);
  }
}
