import { Injectable } from '@angular/core';
import {
  Geolocation,
  Geoposition,
  PositionError,
} from '@awesome-cordova-plugins/geolocation/ngx';

@Injectable({
  providedIn: 'root',
})
export class GeolocationserviceService {
  private lat: any;
  private lng: any;
  private ispermited: boolean = true;

  private watcher: any = null;

  constructor(private geolocation: Geolocation) {}

  get location() {
    return { lat: this.lat, lng: this.lng };
  }

  get isLocation() {
    // return this.ispermited;
    return !!this.lat && !!this.lng;
  }

  init_location() {
    if (!this.watcher) {
      let watch = this.geolocation.watchPosition({ enableHighAccuracy: true });
      this.watcher = watch.subscribe(
        (data: Geoposition | PositionError) => {
          if ('coords' in data) {
            console.log('[GEOLOCATION] => ', data);
            this.lat = data.coords.latitude;
            this.lng = data.coords.longitude;
            this.ispermited = true;
          } else {
            this.ispermited = false;
          }
        },
        (error) => {
          console.log('[GEOERROR] Position error => ', error);
        }
      );
    }
  }
}
