export class ImagesModel {
  public id?: string;
  public id_visit?: string;
  public link?: string;
  public dtupdate?: string;
  public image?: string;

  public static fromJson(js: any) {
    let obj = new ImagesModel();
    obj.id = js.id;
    obj.id_visit = js.id_visit;
    obj.link = js.link;
    obj.dtupdate = js.dtupdate;
    obj.image = js.image;
    return obj;
  }

  public static fromJsonArray(arr: any[]) {
    return arr?.map((e) => ImagesModel.fromJson(e)) || [];
  }

  get dataurl() {
    return this.link || this.image;
  }

  get style() {
    return {
      background: `url("${this.dataurl}")`,
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center',
      width: '100%',
      height: '100%',
    };
  }

  public toJson() {
    return {
      id: this.id,
      id_visit: this.id_visit,
      link: this.link,
      dtupdate: this.dtupdate,
      image: this.image,
    };
  }
}
