import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VisitdriverService } from './drivers/visitdriver.service';
import { GeolocationserviceService } from './services/location/geolocationservice.service';
import { UserloggedService } from './services/storage/userlogged/userlogged.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private visitDriver: VisitdriverService,
    private geo: GeolocationserviceService,
    private userLogged: UserloggedService,
    private route: Router
  ) {
    this.geo.init_location();
    this.userLogged.savedUser;
    if (!this.userLogged.hashcode) {
      this.route.navigate(['/']);
    } else {
      this.route.navigate(['/home']);
    }
  }

  ionViewDidEnter() {
    
  }

  get isqueue() {
    try {
      return this.visitDriver.inQueue;
    } catch (error) {
      return false;
    }
  }

  get size() {
    return this.visitDriver.sizeQueue;
  }
}
